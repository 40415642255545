import { ParcoursPartiePrenanteDto } from '@shared/src/api/parcours-partie-prenante/dto/parcours-partie-prenante.dto';
import { useProjet } from '@shared/src/components/providers/ProjetProvider';
import { ProfileComponent } from '@shared/src/components/questionnaire/Content/ProfileComponent';
import { useSujets } from '@shared/src/hooks/useSujets';
import Layout from '../../components/Layout/Layout';

export type ProfilingPageProps = {
  parcours: ParcoursPartiePrenanteDto | null;
};

export function ProfilingPage(props: ProfilingPageProps) {
  const { parcours } = props;
  const { projet } = useProjet();
  const { sujets } = useSujets(parcours?.id);

  return (
    <Layout projetId={projet?.id}>
      <ProfileComponent
        parcours={parcours}
        stepperLevel={(sujets?.length || 1) + 1}
        baseUrl={`/projets/${projet?.id}`}
      />
    </Layout>
  );
}
