import { CategoriePartiePrenanteDto } from '@shared/src/api/categorie-partie-prenante/dto/categorie-partie-prenante.dto';
import { ExplanationComponent } from '@shared/src/components/questionnaire/Explanation/ExplanationComponent';
import { useParcours } from '@shared/src/hooks/useParcours';
import { useSujets } from '@shared/src/hooks/useSujets';
import { categoriePartiePrenantesService } from '@shared/src/services/CategoriePartiePrenantesService';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';

export function ExplanationPage() {
  const { projetId } = useParams();
  const { parcours } = useParcours(projetId);
  const { sujets } = useSujets(parcours?.id);
  const [category, setCategory] = useState<CategoriePartiePrenanteDto>();
  const categoriePartiePrenante = parcours?.__categoriePartiePrenantes__[0];

  useEffect(() => {
    if (categoriePartiePrenante)
      categoriePartiePrenantesService
        .findOne(categoriePartiePrenante.id)
        .then(function (response) {
          if ('statusCode' in response) {
            return Promise.reject(response);
          }
          setCategory(response.data ?? undefined);
        })
        .catch(function (error) {
          if (error.name === 'AbortError') return;
          console.error(error);
        });
  }, [categoriePartiePrenante]);

  if (!category || !parcours || !sujets) return null;

  return (
    <Layout maxWidth="md" projetId={projetId}>
      <ExplanationComponent
        categorie={category}
        sujets={sujets}
        parcours={parcours}
        updateParcours={true}
        nextStepUrl={`/projets/${projetId}/sujet/1`}
      />
    </Layout>
  );
}
