import { PrivacyPolicy } from '@shared/src/components/legal/PrivacyPolicy';
import Layout from '../../components/Layout/Layout';

export function PrivacyPolicyPage() {
  return (
    <Layout connected={false}>
      <PrivacyPolicy />
    </Layout>
  );
}
