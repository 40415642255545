import { LegalInformation } from '@shared/src/components/legal/LegalInformation';
import Layout from '../../components/Layout/Layout';

export function LegalInformationPage() {
  return (
    <Layout connected={false}>
      <LegalInformation />
    </Layout>
  );
}
