import { useProjet } from '@shared/src/components/providers/ProjetProvider';
import { LastQuestionComponent } from '@shared/src/components/questionnaire/End/LastQuestionComponent';
import { useParcours } from '@shared/src/hooks/useParcours';
import Layout from '../../components/Layout/Layout';

export function LastQuestionPage() {
  const { projet } = useProjet();
  const { parcours } = useParcours(projet?.id);

  if (!parcours || !projet) {
    return null;
  }

  return (
    <Layout projetId={projet?.id}>
      <LastQuestionComponent nextStepUrl={`/projets/${projet?.id}/end`} parcours={parcours} />
    </Layout>
  );
}
