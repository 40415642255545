import { ParcoursPartiePrenanteDto } from '@shared/src/api/parcours-partie-prenante/dto/parcours-partie-prenante.dto';
import { useProjet } from '@shared/src/components/providers/ProjetProvider';
import { EXPERTS_GLOBAL_ID } from '@shared/src/constant/global-constants';
import { StatutParcours } from '@shared/src/enum/parcours-partie-prenante.enum';
import { useAnsweredSujets } from '@shared/src/hooks/useAnsweredSujets';
import { useSujets } from '@shared/src/hooks/useSujets';
import { parcoursService } from '@shared/src/services/ParcoursService';
import { Navigate } from 'react-router-dom';
import { NotFoundPage } from '../not-found';

export type ParcoursPageProps = {
  parcours: ParcoursPartiePrenanteDto | null;
};

/**
 * This page is used to resolve the parcours for
 * this user and redirect to the correct state of parcours
 */
export function ParcoursPage(props: ParcoursPageProps) {
  const { parcours } = props;
  const { projet, isLoading } = useProjet();
  const { sujets } = useSujets(props.parcours?.id);
  const { answeredSujets, isAnsweredSujetsLoading } = useAnsweredSujets(props.parcours?.id);

  if (isLoading || isAnsweredSujetsLoading) {
    return null;
  }

  if (parcours && projet && sujets) {
    switch (parcours.statut) {
      case StatutParcours.INVITE:
        parcoursService.update(parcours.id, { statut: StatutParcours.INSCRIT });
        return <Navigate to={`/projets/${projet.id}/start`} />;
      case StatutParcours.INSCRIT:
        return <Navigate to={`/projets/${projet.id}/start`} />;
      case StatutParcours.DEMARRE:
        if (!answeredSujets) {
          return <Navigate to={`/projets/${projet.id}/sujet/1`} />;
        } else {
          if (answeredSujets.length < sujets.length) {
            return <Navigate to={`/projets/${projet.id}/sujet/` + (answeredSujets.length + 1)} />;
          } else {
            if (!parcours.genre) {
              return <Navigate to={`/projets/${projet.id}/profiling`} />;
            } else {
              if (
                parcours.__categoriePartiePrenantes__.findIndex((categorie) =>
                  EXPERTS_GLOBAL_ID.includes(categorie?.globalId ?? ''),
                ) > -1
              ) {
                return <Navigate to={`/projets/${projet.id}/last-question`} />;
              } else {
                return <Navigate to={`/projets/${projet.id}/end`} />;
              }
            }
          }
        }
      case StatutParcours.TERMINE:
        return <Navigate to={`/projets/${projet.id}/end`} />;

      default:
        return <NotFoundPage />;
    }
  }
  return <NotFoundPage />;
}
