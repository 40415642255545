import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Button, Drawer, IconButton } from '@mui/material';
import LanguageDropdown from '@shared/src/components/LanguageDropdown';
import LogoutAlert from '@shared/src/components/LogoutAlert';
import { organisationsService } from '@shared/src/services/OrganisationsService';
import { utilisateursService } from '@shared/src/services/UtilisateursService';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  connected?: boolean;
  goToHomeButton?: boolean;
  projetId?: string;
};

const HeaderLayout: React.FC<PropsWithChildren<Props>> = ({ connected = true, projetId, goToHomeButton = true }) => {
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [logo, setLogo] = useState<string>('');
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [organisationName, setOrganisationName] = useState<string>('');
  const [hasGoToHomeButton, setHasgoToHomeButton] = useState<boolean>(goToHomeButton);

  useEffect(function () {
    const abortController = new AbortController();
    utilisateursService
      .findUtilisateurProjets({ active: true }, { signal: abortController.signal })
      .then(function (response) {
        if ('statusCode' in response) {
          return Promise.reject(response);
        }
        if (response.data.length <= 1) setHasgoToHomeButton(false);
      })
      .catch(function (error) {
        if (error.name === 'AbortError') return;
        console.error(error);
      });
    return function () {
      abortController.abort();
    };
  }, []);

  if (projetId) {
    organisationsService.findOneByProjetId(projetId).then((res) => {
      if ('data' in res) {
        setOrganisationName(res.data.nom);
        setLogo(res.data.logo ?? '');
      }
    });
  }

  return (
    <>
      <LogoutAlert alertOpen={alertOpen} setAlertOpen={setAlertOpen} />

      <Box
        sx={{
          display: { xs: 'none', md: 'flex' },
          width: '100%',
          backgroundColor: 'primary.light',
          justifyContent: (logo && logo.length > 0) || hasGoToHomeButton ? 'space-between' : 'right',
        }}
      >
        {hasGoToHomeButton && (
          <Button
            variant="text"
            href={'/'}
            startIcon={<HomeOutlinedIcon />}
            sx={{ color: 'text.primary', width: '20rem', justifyContent: 'start' }}
          >
            <FormattedMessage id="header.goToHome-button" />
          </Button>
        )}
        {logo && logo.length > 0 && (
          <Box maxHeight={50} minHeight={50} marginLeft={2} marginY={2}>
            <img src={`${logo}`} alt={`logo-${organisationName}`} height={50} />
          </Box>
        )}

        <Box display="flex" justifyContent="flex-end" alignItems="center" width="20rem">
          <Box sx={{ p: '0.5rem' }}>
            <LanguageDropdown />
          </Box>
          {connected && (
            <Button
              variant="text"
              onClick={() => setAlertOpen(true)}
              startIcon={<LogoutIcon />}
              sx={{ mr: '0.5rem', color: 'text.primary' }}
            >
              <FormattedMessage id="header.logout-button" />
            </Button>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: { xs: 'flex', md: 'none' },
          width: '100%',
          backgroundColor: 'primary.light',
          justifyContent: logo && logo.length ? 'space-between' : 'end',
        }}
      >
        {logo && logo.length > 0 && (
          <Box maxHeight={50} minHeight={50} marginLeft={2} marginY={2}>
            <img src={`${logo}`} alt={`logo-${organisationName}`} height={50} />
          </Box>
        )}
        <IconButton size="large" sx={{ display: { md: 'none' } }} onClick={() => setMenuOpen(true)}>
          <MenuIcon />
        </IconButton>
      </Box>
      <Drawer
        anchor="right"
        variant="temporary" // mobile version
        sx={{
          display: { xs: 'flex', md: 'none' },
          flexDirection: 'column',
          height: '100vh',
          '& .MuiDrawer-paper': { boxSizing: 'border-box', justifyContent: 'space-between', width: '12rem', p: '1rem' },
        }}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
      >
        {hasGoToHomeButton && (
          <Button
            variant="text"
            href={'/'}
            startIcon={<HomeOutlinedIcon />}
            sx={{ color: 'text.primary', justifyContent: 'start' }}
          >
            <FormattedMessage id="header.goToHome-button" />
          </Button>
        )}
        <Box>
          <LanguageDropdown />
          {connected && (
            <Button
              variant="text"
              onClick={() => setAlertOpen(true)}
              startIcon={<LogoutIcon />}
              sx={{ color: 'text.primary', justifyContent: 'start' }}
            >
              <FormattedMessage id="header.logout-button" />
            </Button>
          )}
        </Box>
      </Drawer>
    </>
  );
};

export default HeaderLayout;
