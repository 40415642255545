import { Paper, Typography } from '@mui/material';
import { LoginForm } from '@shared/src/components/login/LoginForm';
import { useUtilisateur } from '@shared/src/components/providers/UtilisateurProvider';
import { UtilisateurRole } from '@shared/src/enum/utilisateur-roles.enum';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';

export function LoginPage() {
  const { invalidateUser, utilisateur } = useUtilisateur();
  const onSignedIn = useCallback(() => {
    // When the user is signed in, we need to invalidate the data to force a reload of the user data
    invalidateUser();
  }, [invalidateUser]);

  if (utilisateur && utilisateur.role === UtilisateurRole.PARTIE_PRENANTE) {
    // Once the user has been successfully fetched, we redirect him to the home page
    return <Navigate to="/" />;
  }

  return (
    <Layout maxWidth="md" connected={false} goToHomeButton={false}>
      <Paper sx={{ borderRadius: '1rem', padding: '1rem' }}>
        <Typography variant="h1" gutterBottom align="center" marginY={2}>
          <FormattedMessage id="login.form.title" />
        </Typography>
        <LoginForm onSignedIn={onSignedIn} />
      </Paper>
    </Layout>
  );
}
