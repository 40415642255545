import { EndComponent } from '@shared/src/components/questionnaire/End/EndComponent';
import { useParams } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';

export function EndPage() {
  const { projetId } = useParams();
  return (
    <Layout projetId={projetId}>
      <EndComponent />
    </Layout>
  );
}
