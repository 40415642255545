import { CookiePolicy } from '@shared/src/components/legal/CookiePolicy';
import Layout from '../../components/Layout/Layout';

export function CookiePolicyPage() {
  return (
    <Layout connected={false}>
      <CookiePolicy />
    </Layout>
  );
}
