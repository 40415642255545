import { ProtectedRoute } from '@shared/src/components/ProtectedRoute';
import { Provider } from '@shared/src/components/providers/ProjetProvider';
import { UtilisateurRole } from '@shared/src/enum/utilisateur-roles.enum';
import { useParcours } from '@shared/src/hooks/useParcours';
import { BeforeFetchEvent, addEventListener, removeEventListener } from '@shared/src/services/HttpService';
import { useCallback, useEffect } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { EndPage } from './EndPage';
import { ExplanationPage } from './ExplanationPage';
import { LastQuestionPage } from './LastQuestionPage';
import { ParcoursPage } from './ParcoursPage';
import { ProfilingPage } from './ProfilingPage';
import { SujetPage } from './SujetPage';

export function ProjetsRouter() {
  const { projetId } = useParams();

  const { parcours, isLoading } = useParcours(projetId);

  const setupProjetId = useCallback(
    function onBeforeFetch(event: BeforeFetchEvent) {
      if (!projetId) return;
      const url = new URL(event.url);
      url.searchParams.set('projetId', projetId);
      event.url = url.toString();
    },
    [projetId],
  );

  useEffect(() => {
    if (!projetId) return;
    addEventListener('before:fetch', setupProjetId);
    return function () {
      removeEventListener('before:fetch', setupProjetId);
    };
  }, [projetId, setupProjetId]);

  if (isLoading) {
    return null;
  }

  return (
    <Provider projetId={projetId}>
      <Routes>
        <Route element={<ProtectedRoute role={[UtilisateurRole.PARTIE_PRENANTE]} />}>
          <Route path="/" element={<ParcoursPage parcours={parcours} />} />
          <Route path="/start" Component={ExplanationPage} />
          <Route path="/sujet/:position" element={<SujetPage parcours={parcours} />} />
          <Route path="/profiling" element={<ProfilingPage parcours={parcours} />} />
          <Route path="/last-question" Component={LastQuestionPage} />
          <Route path="/end" Component={EndPage} />
        </Route>
      </Routes>
    </Provider>
  );
}
