import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Button, Container, Grid } from '@mui/material';
import { ProjetDto } from '@shared/src/api/projet/dto/projet.dto';
import { NoLogo } from '@shared/src/components/NoLogo';
import { utilisateursService } from '@shared/src/services/UtilisateursService';
import { PropsWithChildren, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import { NotFoundPage } from '../not-found';

type BlockProps = {
  projet: ProjetDto;
};

const ProjetBlock: React.FC<PropsWithChildren<BlockProps>> = ({ projet }) => {
  return (
    <Box sx={{ height: '5rem' }}>
      <Button
        key={projet.id}
        href={`/projets/${projet.id}`}
        variant="contained"
        endIcon={<ArrowForwardIcon />}
        sx={{
          backgroundColor: 'primary.light',
          color: 'text.primary',
          ':hover': { backgroundColor: 'secondary.dark' },
          height: '100%',
          zIndex: 2,
          justifyContent: 'space-between',
          pr: '1rem',
          width: '100%',
        }}
      >
        <Box
          sx={{
            minWidth: 60,
            maxWidth: 60,
          }}
        >
          {projet.organisation?.logo && projet.organisation.logo.length > 0 && (
            <img src={`${projet.organisation.logo}`} alt="logo" width={60} />
          )}
          {(!projet.organisation?.logo || projet.organisation.logo.length === 0) && <NoLogo size="medium" />}
        </Box>
        {projet.organisation?.nom} - {projet.nom}
      </Button>
    </Box>
  );
};

/**
 * This page is used to resolve the project for
 * this user and redirect to the correct project
 */
export function ProjetsPage() {
  const [projets, setProjets] = useState<ProjetDto[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [authorized, setAuthorized] = useState<boolean>(true);

  useEffect(function () {
    setLoading(true);
    const abortController = new AbortController();
    utilisateursService
      .findUtilisateurProjets({ active: true }, { signal: abortController.signal })
      .then(function (response) {
        if ('statusCode' in response) {
          return Promise.reject(response);
        }
        setProjets(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        if (error.statusCode === 403) {
          setAuthorized(false);
        }
        if (error.name === 'AbortError') return;
        setLoading(false);
        console.error(error);
      });
    return function () {
      abortController.abort();
    };
  }, []);

  if (isLoading) {
    return null;
  }

  if (!authorized) {
    return <Navigate to="/auth/login" />;
  }

  if (projets.length === 0) {
    return (
      <Layout goToHomeButton={false}>
        <Container>
          <FormattedMessage id="projet.noProjet.content" />
        </Container>
      </Layout>
    );
  }

  if (projets.length === 1) {
    return <Navigate to={`/projets/${projets[0].id}`} />;
  }

  if (projets.length > 1) {
    return (
      <Layout goToHomeButton={false}>
        <Container>
          <Grid container spacing={3}>
            {projets.map((projet) => (
              <Grid item xs={12} sm={6} md={4} key={projet.id}>
                <ProjetBlock projet={projet} />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Layout>
    );
  }

  return <NotFoundPage />;
}
